import React from "react";
import styles from "./marketing.module.css";

function Marketing() {
  return (
    <section id="marketing">
      <div className={`${styles.container_tittle} my_container mt-8 p-2`}>
        <div className={`${styles.tittle}`}>
          MARKETING <br className={styles.visible} /> SCIENCE PROGRAM
        </div>
        {/* <button
          type="button"
          className={`${styles.button} underline text-xs sm:text-xl md:text-xl lg:text-xl text-white font-bold border-round cursor-pointer mt-2 sm:m-0 sm:ml-1`}
        >
          KNOW OUR PROGRAM
        </button> */}
      </div>
      <div className={`${styles.container_text} my_container p-1 sm:p-2`}>
        <div className="grid">
          <div className="col-12 sm:col-4 flex align-items-center justify-content-center">
            <div
              className={`${styles.why} m-2 text-xl sm:text-left text-color-secondary`}
            >
              Why you should choose
              <br />
              our services:
            </div>
          </div>
          <div
            className={`${styles.list} col-12 sm:col-4 flex flex-column text-color`}
          >
            <ul>
              <li>Product Service Definition</li>
              <li>Customer Segmentation</li>
              <li>Goals and Projected Subproducts</li>
              <li>Leads Qualification Definition</li>
            </ul>
          </div>
          <div
            className={`${styles.list} col-12 sm:col-4 flex flex-column text-color mb-4 sm:mb-0`}
          >
            <ul>
              <li>Evaluation Parameters Definition</li>
              <li>Data Gathering and Analytics</li>
              <li>Landing Page and Social Media Design</li>
            </ul>
          </div>
          {/* <div className={`${styles.list} col-12 sm:col-4 flex flex-column justify-content-center text-color`}>
            <ul>
              <li>Product Service Definition</li>
              <li>Customer Segmentation</li>
              <li>Goals and Projected Subproducts</li>
              <li>Leads Qualification Definition</li>
              <li>Evaluation Parameters Definition</li>
            </ul>
          </div>
          <div className={`${styles.list} col-12 sm:col-4 flex flex-column justify-content-center mb-4 sm:mb-2 text-color`}>
            <ul>
              <li>Goals and Projected Subproducts</li>
              <li>Leads Qualification Definition</li>
              <li>Data Gathering and Analytics</li>
              <li>Landing Page and Social</li>
              <li>Media Design and More</li>
            </ul>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default Marketing;
