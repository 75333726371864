import React from "react";
// import Footer from "./components/footer";
import FooterNew from "./components/footerNew";
import Menu from "./components/MenuScroll/MenuScroll";

import { Outlet } from "react-router-dom";

function App() {
  return (
    <>
      <Menu />
      <main>
        <Outlet />
      </main>
      <FooterNew />
    </>
  );
}
export default App;
