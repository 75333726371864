import React from "react";
import styles from "./ofers.module.css";

function Ofers() {
  return (
    <div
      className={`${styles.container} flex flex-column justify-content-center py-5 px-4 md:px-2 md:py-8`}
      id="ofers"
    >
      <p
        className={`${styles.tittle} text-2xl sm:text-4xl md:text-4xl lg:text-5xl xl:text-5xl text-white font-bold text-center line-height-2`}
      >
        Building more awareness of your
        <br />
        business is one of marketing's
        <br />
        biggest advantages.
      </p>

      <hr className={`${styles.hr} my-4 my-2 md:my-4`}></hr>
      <p
        className={`${styles.subTittleT} text-xs sm:text-xs md:text-xs lg:text-xl xl:text-xl text-white text-center  line-height-1`}
      >
        If we are unsuccessful in attracting clients, <br />
        we will undoubtedly fall short of our sales targets. <br />
        Client acquisition plays a crucial role in achieving sales goals.
      </p>

      <div className="col-8 sm:col-4 text-center w-full mt-4">
        <button
          type="button"
          className="btn-secondary"
          onClick={() => {
            window.open(
              "https://meetings.hubspot.com/sales-signitiva/one-to-one",
              "_blank"
            );
          }}
        >
          Let´s connect
        </button>
      </div>
      {/* <div className="mt-6 text-center">
        <button
          type="button"
          className={`${styles.button} underline sm:text-xl md:text-xl lg:text-xl text-white font-bold border-round cursor-pointer`}
        >
          OUR OFFERS
        </button>
      </div> */}
    </div>
  );
}

export default Ofers;
