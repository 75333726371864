import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import fanalytics from "../services/fanalytics";
import styles from "./menufootter.module.css";

const MenuDesktopFooter = () => {
  function QueryNavLink({ to, ...props }) {
    let location = useLocation();
    return (
      <NavLink
        to={to + location.search}
        {...props}
        aria-label={to}
        title={to}
      />
    );
  }

  return (
    <div className="menuFooterSig md:px-4">
      <nav>
        <ul className={styles.navLinks}>
          <li>
            <QueryNavLink
              className={({ isActive }) => {
                return isActive ? "activeMovil" : "";
              }}
              to="/"
              onClick={() => {
                fanalytics.logEvents("MenuDesktop", "ABOUT US");
              }}
            >
              HOME
            </QueryNavLink>
          </li>
          {/* <li>
            <QueryNavLink
              className={({ isActive }) => {
                return isActive ? "activeMovil" : "";
              }}
              to="/About-Us"
              onClick={() => {
                fanalytics.logEvents("MenuDesktop", "ABOUT US");
              }}
            >
              ABOUT US
            </QueryNavLink>
          </li> */}

          <li>
            <QueryNavLink
              className={({ isActive }) => {
                return isActive ? "activeMovil" : "";
              }}
              to="/Data-Analytics"
              onClick={() => {
                fanalytics.logEvents("MenuDesktop", "DATA ANALYTICS");
              }}
            >
              DATA ANALYTICS
            </QueryNavLink>
          </li>
          <li>
            <QueryNavLink
              className={({ isActive }) => {
                return isActive ? "activeMovil" : "";
              }}
              to="/Process-Automation"
              onClick={() => {
                fanalytics.logEvents("MenuDesktop", "PROCESS AUTOMATION");
              }}
            >
              PROCESS AUTOMATION
            </QueryNavLink>
          </li>
          <li>
            <QueryNavLink
              className={({ isActive }) => {
                return isActive ? "activeMovil" : "";
              }}
              to="/Marketing-Science"
              onClick={() => {
                fanalytics.logEvents("MenuDesktop", "MARKETING SCIENCE");
              }}
            >
              MARKETING SCIENCE
            </QueryNavLink>
          </li>
          {/* <li>
            <QueryNavLink
              className={({ isActive }) => {
                return isActive ? "activeMovil" : "";
              }}
              to="/"
              onClick={() => {
                fanalytics.logEvents("MenuDesktop", "BLOG");
              }}
            >
              BLOG
            </QueryNavLink>
          </li> */}
          <li>
            <QueryNavLink
              className={({ isActive }) => {
                return isActive ? "activeMovil" : "";
              }}
              to="/Blog"
              onClick={() => {
                fanalytics.logEvents("MenuDesktop", "ABOUT US");
              }}
            >
              BLOG
            </QueryNavLink>
          </li>
          <li>
            <QueryNavLink
              className={({ isActive }) => {
                return isActive ? "activeMovil" : "";
              }}
              to="/ContactUs"
              onClick={() => {
                fanalytics.logEvents("MenuDesktop", "CONTACT US");
              }}
            >
              CONTACT US
            </QueryNavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default MenuDesktopFooter;
