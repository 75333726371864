import React from "react";
import HubspotForm from "react-hubspot-form";
import styles from "./contacts.module.css";

function Contacts() {
  return (
    <section className={`${styles.container}`}>
      {/* <h3 className="text-2xl xl:text-5xl sm:text-3xl font-bold text-center mb-4 text-color">
        Let's do Business Together!
      </h3> */}
      <HubspotForm
        portalId="22470474"
        formId="65031184-eb51-4f41-abc2-7e37bbcd563a"
        onSubmit={() => console.log("Submit!")}
        onReady={(form) => console.log("Form ready!")}
        loading={<div>Loading...</div>}
      />
    </section>
  );
}

export default Contacts;
