import React from "react";
import styles from "./customers.module.css";
import hands from "../../../assets/images/hands.svg";
import person from "../../../assets/images/person.svg";
import tape from "../../../assets/images/tape.svg";

function Customers() {
  return (
    <section className={`${styles.container}`} id="customers">
      <div className="text-center sm:text-left text-xl sm:text-3xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mt-4 line-height-2 text-white line-height-2">
        Customers can better comprehend your
        <br />
        value proposition by using marketing
      </div>
      <br />
      <br />
      <hr className={`${styles.hr}`}></hr>

      <div className="grid mt-6">
        <div className="col-12 md:col-4 hvr-grow flex align-items-center justify-content-center">
          <div className="grid">
            <div className="col-12 md:col-6 flex align-items-center justify-content-center">
              <div className={`${styles.container_image}`}>
                <img src={hands} alt="hands" />
              </div>
            </div>
            <div className="col-12 md:col-6 flex align-items-center justify-content-center">
              <div className=" ml-0 sm:ml-2 text-white line-height-2 text-lg">
                Creating a lasting
                <br />
                impression with
                <br />
                your customers.
              </div>
            </div>

            <div className="col-12">
              <div className="grid">
                <div className="col-10 col-offset-1 containerCutomer">
                  <p>
                    Leave a positive and memorable impact on your customers.
                    Deliver exceptional experiences, build strong relationships,
                    and ensure customers are satisfied, loyal, and likely to
                    recommend the business to others.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 md:col-4 hvr-grow flex align-items-center justify-content-center">
          <div className="grid">
            <div className="col-12 md:col-6 flex align-items-center justify-content-center">
              <div className={`${styles.container_image}`}>
                <img src={person} alt="person" />
              </div>
            </div>
            <div className="col-12 md:col-6 flex align-items-center justify-content-center">
              <div className="ml-0 sm:ml-2 text-white line-height-2 text-lg sm:pl-4 sm:pr-4">
                We work with
                <br /> you to develop your case.
              </div>
            </div>

            <div className="col-12">
              <div className="grid">
                <div className="col-10 col-offset-1 containerCutomer">
                  <p>
                    We team up with you to create and strengthen your proposal.
                    Our dedicated team will provide support, guidance, and
                    expertise to help you develop a tailored concept for your
                    services or products.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 md:col-4 hvr-grow flex align-items-center justify-content-center">
          <div className="grid">
            <div className="col-12 md:col-6 flex align-items-center justify-content-center">
              <div className={`${styles.container_image}`}>
                <img src={tape} alt="tape" />
              </div>
            </div>
            <div className="col-12 md:col-6 flex align-items-center justify-content-center">
              <div className=" ml-0 sm:ml-2 text-white line-height-2 text-lg">
                Marketing aids
                <br />
                distinguishes you
                <br />
                and your company.
              </div>
            </div>
            <div className="col-12">
              <div className="grid">
                <div className="col-10 col-offset-1 containerCutomer mt-5">
                  <p>
                    Effective marketing strategies set you apart from
                    competitors and establish a unique identity for your
                    business. With them, you can showcase your brand's
                    strengths, communicate your value proposition, and stand out
                    in the market, attracting and retaining customers.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Customers;
