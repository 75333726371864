import React from "react";
import "./Home.css";
//MEDIOS
import graficas from "../../assets/images/icon-graficas.png";
import alza from "../../assets/images/icon-alza.png";

function ContactSectionNew() {
  return (
    <>
      <div className="grid-nogutter">
        <div className="col-12 seccionNewC">
          <div className="grid seccionNewCBorder">
            <div className="col-12 md:col-2">
              <img src={graficas} alt="Graficas" />
            </div>
            <div className="col-12 md:col-8">
              <p>
                Marketing Science is revolutionizing the way businesses work and
                achieve success. Using data insights and strategic approaches,
                companies can make informed decisions and achieve impressive
                results.
              </p>
              <p>
                According to recent studies, businesses using Marketing Science
                techniques experience an average revenue increase of 25% and a
                15% reduction in marketing costs.
              </p>
              <p>
                With Marketing Science, businesses can unlock new opportunities,
                optimize their strategies, and stay ahead of the competition.
                Are you ready to tap into the potential of data-driven marketing
                and achieve remarkable growth?
              </p>
              <button
                className="btn-contact"
                onClick={() => {
                  window.open("https://signitiva.com/ContactUs", "_blank");
                }}
              >
                Contact Us
              </button>
            </div>
            <div className="col-12 md:col-2">
              <img src={alza} alt="Graficas" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactSectionNew;
