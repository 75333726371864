import React from "react";
import girl from "../../../assets/images/girl.png";

function HeaderRight({ styles }) {
  return (
    <div
      className={`${styles.header_left} h-f col-12 sm:col-7 md:col-7 lg:col-7 xl:col-7 p-0`}
    >
      <div className={styles.container_image}>
        <img src={girl} alt="Logo" />
        {/* <div
          className={`${styles.theMore} col-12 font-italic text-base md:text-xs lg:text-base text-color font-bold text-center sm:text-right md:text-right lg:text-right mt-2 line-height-2`}
        >
          The more you invest in marketing, the
          <br /> greater your chances of a higher
          <br />
          ROI (return on investment).
        </div> */}
      </div>
    </div>
  );
}

export default HeaderRight;
