import React from "react";

function HeaderLeft({ styles }) {
  return (
    <div className="col-12 sm:col-5 md:col-5 lg:col-5 xl:col-5 flex flex-column justify-content-center">
      <div
        className={`${styles.tittle} text-6xl sm:text-6xl md:text-6xl lg:text-7xl xl:text-7xl text-primary font-bold text-left`}
      >
        <p>
          <span className="text-color">HOW WILL</span>
        </p>
        <p>YOUR</p>
        <p> BUSINESS</p> <p> GROW</p>
      </div>

      <div
        className={`${styles.subTittle} font-italic text-base sm:text-xl md:text-xl lg:text-xl text-color font-bold text-left mt-4 hvr-skew-backward`}
      >
        <p>IF NO ONE KNOWS</p>
        <p>ABOUT IT?</p>
      </div>

      <div className="mt-4 md:mt-3 text-left">
        <a
          href="https://signitiva.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button type="button" className="btn-Gen">
            VIEW MORE
          </button>
        </a>
      </div>
    </div>
  );
}

export default HeaderLeft;
