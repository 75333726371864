import React from "react";
import logo from "../../assets/images/signitiva-logo.webp";
import { bubble as Menu } from "react-burger-menu";
import { NavLink, useLocation } from "react-router-dom";
import fanalytics from "../services/fanalytics";
import styles from "./menu.module.css";

var styles2 = {
  bmBurgerButton: {
    position: "absolute",
    width: "36px",
    height: "30px",
    right: "20px",
    top: "26px",
  },
  bmBurgerBars: {
    background: "#373a47",
  },
  bmBurgerBarsHover: {
    background: "#fff",
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
  },
  bmCross: {
    background: "#bdc3c7",
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
  },
  bmMenu: {
    paddingTop: "2rem",
    fontSize: "0.8rem",
    width: "100%",
    background: "#fff",
    borderRight: "1px solid #bdc3c7",
    textAlign: "center",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "#b8b7ad",
    padding: "0.8em",
  },
  bmItem: {
    display: "block",
    color: "var(--text-color)",
    marginTop: "20px",
    borderBottom: "1px solid var(--text-color)",
  },
  bmOverlay: {
    background: "#fff",
  },
};

const MenuMovil = () => {
  function QueryNavLink({ to, ...props }) {
    let location = useLocation();
    return (
      <NavLink
        to={to + location.search}
        {...props}
        aria-label={to}
        title={to}
      />
    );
  }

  return (
    <>
      <Menu styles={styles2} width={"60%"} noOverlay>
        <QueryNavLink
          className={({ isActive }) => {
            return isActive ? "activeMovil" : "";
          }}
          to="/"
          onClick={() => {
            fanalytics.logEvents("MenuMovil", "ABOUT US");
          }}
        >
          HOME
        </QueryNavLink>
        {/* <QueryNavLink
          className={({ isActive }) => {
            return isActive ? "activeMovil" : "";
          }}
          to="/About-Us"
          onClick={() => {
            fanalytics.logEvents("MenuMovil", "ABOUT US");
          }}
        >
          ABOUT US
        </QueryNavLink> */}

        <QueryNavLink
          className={({ isActive }) => {
            return isActive ? "activeMovil" : "";
          }}
          to="/Data-Analytics"
          onClick={() => {
            fanalytics.logEvents("MenuMovil", "DATA ANALYTICS");
          }}
        >
          DATA ANALYTICS
        </QueryNavLink>
        <QueryNavLink
          className={({ isActive }) => {
            return isActive ? "activeMovil" : "";
          }}
          to="/Process-Automation"
          aria-label="Process-Automation"
          title="Process-Automation"
          onClick={() => {
            fanalytics.logEvents("MenuMovil", "PROCESS AUTOMATION");
          }}
        >
          PROCESS AUTOMATION
        </QueryNavLink>
        <QueryNavLink
          className={({ isActive }) => {
            return isActive ? "activeMovil" : "";
          }}
          to="/Marketing-Science"
          onClick={() => {
            fanalytics.logEvents("MenuMovil", "MARKETING SCIENCE");
          }}
        >
          MARKETING SCIENCE
        </QueryNavLink>
        <QueryNavLink
          className={({ isActive }) => {
            return isActive ? "activeMovil" : "";
          }}
          to="/Blog"
          onClick={() => {
            fanalytics.logEvents("MenuMovil", "ABOUT US");
          }}
        >
          BLOG
        </QueryNavLink>
        <QueryNavLink
          className={({ isActive }) => {
            return isActive ? "activeMovil" : "";
          }}
          to="/ContactUs"
          onClick={() => {
            fanalytics.logEvents("MenuMovil", "CONTACT US");
          }}
        >
          CONTACT US
        </QueryNavLink>
      </Menu>
      <div className="my_container flex justify-content-end align-items-center bg-red h-4rem p-2 visiblesm">
        <div className={`mr-auto ${styles.logo}`}>
          <img
            src={logo}
            alt="Logo"
            onClick={() => {
              window.open("https://signitiva.com/");
              fanalytics.logEvents("logo", "home");
            }}
          />
        </div>
      </div>
    </>
  );
};

export default MenuMovil;
