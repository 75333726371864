import React from "react";
import styles from "./partners.module.css";
import hubspot from "../../../assets/images/hubspot.png";
import tableau from "../../../assets/images/tableau.png";
import uipatch from "../../../assets/images/uipatch.png";
import microsoft from "../../../assets/images/microsoft.png";

function Partners() {
  return (
    <section id="partners">
      <div className="flex justify-content-center align-content-center mt-8">
        <div className={styles.lineLeft}></div>
        <h1
          className={`${styles.title} text-2xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-5xl`}
        >
          OUR PARTNERS
        </h1>
        <div className={styles.lineRight}></div>
      </div>

      <div className="grid my_container mt-3">
        <div className="col-12 md:col-10 md:col-offset-1">
          <div className="grid">
            <div
              className={`${styles.container_image} hvr-grow col-12 sm:col-3 md:col-3 lg:col-3 xl:col-3 mt-4`}
            >
              <img src={hubspot} alt="Hubspot" />
            </div>
            <div
              className={`${styles.container_image} hvr-grow col-12 sm:col-3 md:col-3 lg:col-3 xl:col-3 mt-4`}
            >
              <img src={tableau} alt="Tableau" />
            </div>
            <div
              className={`${styles.container_image} hvr-grow col-12 sm:col-3 md:col-3 lg:col-3 xl:col-3 mt-4`}
            >
              <img src={uipatch} alt="UiPatch" />
            </div>
            <div
              className={`${styles.container_image} hvr-grow col-12 sm:col-3 md:col-3 lg:col-3 xl:col-3  mt-4`}
            >
              <img src={microsoft} alt="Microsoft" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Partners;
