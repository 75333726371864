import React from "react";
import Ofers from "./ofers";
import ContactSectionNew from "./ContactSectionNew";
import Partners from "./partners";
import Marketing from "./marketing";
import Signitiva from "./signitiva";
import Customers from "./customers";
import Contacts from "./contacts";
import Header from "./header";

// import Video from "./Video/VideoMovil";

function Home() {
  return (
    <>
      <Header />
      <Ofers />
      <ContactSectionNew />
      <Partners />
      <Marketing />
      <Signitiva />
      <Customers />
      {/* <Video /> */}
      <Contacts />
    </>
  );
}

export default Home;
