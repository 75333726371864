import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import reportWebVitals from "./reportWebVitals";
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./primeflex.scss";
import "./index.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
// import DataAnalytics from "./pages/DataAnalytics";
// import ProcessAutomation from "./pages/ProcessAutomation";
// import MarketingScience from "./pages/MarketingScience";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<Home />} />
        {/* <Route path="DataAnalytics" element={<DataAnalytics />} />
        <Route path="ProcessAutomation" element={<ProcessAutomation />} />
        <Route path="MarketingScience" element={<MarketingScience />} /> */}
        <Route
          path="*"
          element={
            <main>
              <p className="text-center my-8" >There's nothing here!</p>
            </main>
          }
        ></Route>
      </Route>
    </Routes>
  </BrowserRouter>
);

reportWebVitals();
