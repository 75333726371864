import React from "react";
import styles from "./header.module.css";
import HeaderLeft from "./HeaderLeft";
import HeaderRight from "./HeaderRight";
const Header = () => {
  return (
    <header className={styles.header} id="abouts">
      <div className={`${styles.headers} grid my_container mt-4`}>
        <HeaderLeft styles={styles} />
        <HeaderRight styles={styles} />
      </div>
    </header>
  );
};

export default Header;
