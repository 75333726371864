import React from "react";
import work from "../../../assets/images/work.png";
import styles from "./signitiva.module.css";

function Signitiva() {
  return (
    <section className={`${styles.container} my_container`} id="signitiva">
      <div className="text-xl sm:text-4xl md:text-4xl lg:text-5xl xl:text-5xl font-bold text-center line-height-1 text-color line-height-2">
        <div
          className={`${styles.container_image} flex justify-content-center align-content-center mb-4`}
        >
          <img src={work} alt="work" />
        </div>
        Sign<span className="text-primary">it</span>iva helps with building
        awareness and
        <br />
        will assist you with{" "}
        <span className="text-primary">increasing sales</span>
      </div>
      <hr className={`${styles.hr} mt-4 sm:mt-4 md:mt-6`}></hr>

      <div className="grid">
        <div className="col-12 md:col-6 md:col-offset-3">
          <div
            className={`${styles.subTitle} text text-center mt-4 sm:mt-6 text-xs sm:text-xl line-height-2`}
          >
            Our goal is to assist you to establish an authentic relationship
            with your customers through the power of marketing. Your company and
            brand identity should be more than just a name.
          </div>
          <a
            href="https://signitiva.com/Marketing-Science"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className={`${styles.learnmore} text-center text-bold font-bold mt-4 cursor-pointer`}
            >
              Learn more
            </div>
          </a>
        </div>
      </div>
    </section>
  );
}

export default Signitiva;
