import React from "react";
import logo from "../../assets/images/signitiva-logo.webp";
import { NavLink, useLocation } from "react-router-dom";
import fanalytics from "../services/fanalytics";
import styles from "./menu.module.css";

const MenuDesktop = () => {
  function QueryNavLink({ to, ...props }) {
    let location = useLocation();
    return (
      <NavLink
        to={to + location.search}
        {...props}
        aria-label={to}
        title={to}
      />
    );
  }

  return (
    <div className="flex justify-content-end align-items-center bg-red px-4 h-5rem visiblemd">
      <div className={`mr-auto ${styles.logo}`}>
        <img
          src={logo}
          alt="Logo"
          onClick={() => {
            window.open("https://signitiva.com/");
            fanalytics.logEvents("logo", "home");
          }}
        />
      </div>
      <nav>
        <ul className={styles.navLinks}>
          <li>
            <QueryNavLink
              className={({ isActive }) => {
                return isActive ? "active" : "";
              }}
              to="/"
              onClick={() => {
                fanalytics.logEvents("MenuDesktop", "ABOUT US");
              }}
            >
              HOME
            </QueryNavLink>
          </li>
          {/* <li>
            <QueryNavLink
              className={({ isActive }) => {
                return isActive ? "active" : "";
              }}
              to="/About-Us"
              onClick={() => {
                fanalytics.logEvents("MenuDesktop", "ABOUT US");
              }}
            >
              ABOUT US
            </QueryNavLink>
          </li> */}

          <li>
            <QueryNavLink
              className={({ isActive }) => {
                return isActive ? "active" : "";
              }}
              to="/Data-Analytics"
              onClick={() => {
                fanalytics.logEvents("MenuDesktop", "DATA ANALYTICS");
              }}
            >
              DATA ANALYTICS
            </QueryNavLink>
          </li>
          <li>
            <QueryNavLink
              className={({ isActive }) => {
                return isActive ? "active" : "";
              }}
              to="/Process-Automation"
              onClick={() => {
                fanalytics.logEvents("MenuDesktop", "PROCESS AUTOMATION");
              }}
            >
              PROCESS AUTOMATION
            </QueryNavLink>
          </li>
          <li>
            <QueryNavLink
              className={({ isActive }) => {
                return isActive ? "active" : "";
              }}
              to="/Marketing-Science"
              onClick={() => {
                fanalytics.logEvents("MenuDesktop", "MARKETING SCIENCE");
              }}
            >
              MARKETING SCIENCE
            </QueryNavLink>
          </li>
          <li>
            <QueryNavLink
              className={({ isActive }) => {
                return isActive ? "active" : "";
              }}
              to="/Blog"
              onClick={() => {
                fanalytics.logEvents("MenuDesktop", "ABOUT US");
              }}
            >
              BLOG
            </QueryNavLink>
          </li>
          <li>
            <QueryNavLink
              className={({ isActive }) => {
                return isActive ? "active" : "";
              }}
              to="/ContactUs"
              onClick={() => {
                fanalytics.logEvents("MenuDesktop", "CONTACT US");
              }}
            >
              CONTACT US
            </QueryNavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default MenuDesktop;
