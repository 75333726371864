import React from "react";
import LogoF from "../../assets/images/signitiva-logo.webp";
import "./FooterNew.css";
import MenuFooter from "../menu/MenuDesktopFooter";

function Footer() {
  return (
    <footer className="grid-nogutter footerNewSeccion">
      <div className="col-12 md:col-10 md:col-offset-1 py-4">
        <div className="grid">
          <div className="col-12 md:col-3 logoFooter">
            <a href="/">
              <img src={LogoF} alt="Logo Signitiva" />
            </a>
          </div>
          <div className="col-12 md:col-9">
            <MenuFooter />
          </div>
        </div>

        <div className="grid py-4">
          <div className="col-12 md:col-3">
            <ul className="lisSocial">
              <li>
                <a
                  href="https://www.youtube.com/channel/UCvhJMZysZ52uQhwDNqwX5fQ"
                  target="_blank"
                  rel="noreferrer noopener"
                  aria-label="Linkedin"
                  // onClick={() => {
                  //   fanalytics.logEvents("FooterSocial", "Linkedin");
                  // }}
                  className="hvr-float"
                >
                  <span className="ocultarse">Youtube</span>
                  <i className="fa-brands fa-youtube"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/signitiva/"
                  target="_blank"
                  rel="noreferrer noopener"
                  aria-label="Linkedin"
                  // onClick={() => {
                  //   fanalytics.logEvents("FooterSocial", "Linkedin");
                  // }}
                  className="hvr-float"
                >
                  <span className="ocultarse">Linkedin</span>
                  <i className="fa-brands fa-linkedin"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/signitiva.us/"
                  target="_blank"
                  rel="noreferrer noopener"
                  aria-label="Instagram"
                  // onClick={() => {
                  //   fanalytics.logEvents("FooterSocial", "Instagram");
                  // }}
                  className="hvr-float"
                >
                  <span className="ocultarse">Instagram</span>
                  <i className="fa-brands fa-instagram"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/signitiva"
                  target="_blank"
                  rel="noreferrer noopener"
                  aria-label="Whatsapp"
                  // onClick={() => {
                  //   fanalytics.logEvents("FooterSocial", "Whatsapp");
                  // }}
                  className="hvr-float"
                >
                  <span className="ocultarse">Whatsapp</span>
                  <i className="fa-brands fa-square-facebook"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-12 md:col-6 footerContacto">
            <h2>Contact us</h2>
            <a
              href="mailto:contact@signitiva.com"
              target="_blank"
              rel="noreferrer"
              className="hvr-icon-back btnEmail"
            >
              <i className="fa-solid fa-envelope hvr-icon"></i> email
            </a>
            <p className="pTexto">(713) 955-2167 | (424) 248-2688</p>
            <p className="pTexto">www.signitiva.com</p>
          </div>
          <div className="col-12 md:col-3 lisSocial footerContacto">
            <h3>POWERED BY SIGNITIVA</h3>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
