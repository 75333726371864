import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";

const fanalytics = {};

fanalytics.logEvents = (event, names) => {
  logEvent(analytics, event, {
    content_type: "menu_items",
    name: names,
  });
};

export default fanalytics;
